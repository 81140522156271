@import url(https://fonts.googleapis.com/css?family=Ubuntu:400,700);
@import url(https://weloveiconfonts.com/api/?family=entypo|fontawesome|zocial);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: icomoon;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #C6D8FF33;
  /* background: #F5F5F5; */
  font-size:14px;
  font-weight: 500;
  font-family:Josefin-sans
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --rating-size: 2.5rem;
  --bar-size: 0.16rem;
  --background-color: #e1dfdd;
  --rating-color-default: #59504b;
  --rating-color-background: #F5F5F5;
  --rating-color-good: #888482;
  --rating-color-meh: #888482;
  --rating-color-bad: #e74c3c;
}

/* Rating item */
.rating {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  
  background: var(--rating-color-default);
  color: var(--rating-color-default);
  width: var(--rating-size);
  height: var(--rating-size);

  /* Basic style for the text */
  font-size: calc(var(--rating-size) / 3);
  line-height: 1;
}

/* Rating circle content */
.rating span {
  position: relative;
  display: flex;
  font-weight: bold;
  z-index: 2;
}

.rating span small {
  font-size: 0.5em;
  font-weight: 900;
  align-self: center;
}

/* Bar mask, creates an inner circle with the same color as thee background */
.rating::after {
  content: "";
  position: absolute;
  top: var(--bar-size);
  right: var(--bar-size);
  bottom: var(--bar-size);
  left: var(--bar-size);
  background: var(--background-primary);
  border-radius: inherit;
  z-index: 1;
}

/* Bar background */
.rating::before {
  content: "";
  position: absolute;
  top: var(--bar-size);
  right: var(--bar-size);
  bottom: var(--bar-size);
  left: var(--bar-size);
  border-radius: inherit;
  box-shadow: 0 0 0 1rem #e2d3d34d;
  z-index: -1;
}

/* Classes to give different colors to ratings, based on their score */
.rating.good {
  background: var(--rating-color-good);
  color: var(--rating-color-good);
}

.rating.meh {
  background: var(--rating-color-meh);
  color: var(--rating-color-meh);
}

.rating.bad {
  background: var(--rating-color-bad);
  color: var(--rating-color-bad);
}

.animate-fadeOut{
  animation: fadeOutAnim;
  opacity:0.2;
}
.animate-fadeIn{
  animation: fadeInAnim;
  opacity:1;
}

@-webkit-keyframes fadeOutAnim{
  0%   {opacity:0;}
  20%   {opacity:0.3;}
  60%   {opacity:0.6;}
  100% {opacity:1}
}
@-webkit-keyframes fadeInAnim{
  0%   {opacity:1;}
  20%   {opacity:0.6;}
  60%   {opacity:0.3;}
  100% {opacity:0;}
}
table tr, td{
  border:1px solid #292929;
  padding: 5px
}
th{
  font-weight: 400;
}
td{
  font-weight: 300;
}
td, th { 
	padding: 8px; 
	border-bottom: 1px solid #ccc;
	text-align: center; 
	font-size: 16px;
  font-family: Lexend Deca;
	}
  td:first-child {
    text-align: left;
    border-bottom: 1px solid #ccc; 
  }

  tr:nth-of-type(odd) {
    background-color: #efefef;
}
  tr:nth-of-type(ecen) {
    background-color: #f9f9f9;
}

.hover-underline-animation {
  position: relative;
  color: #fff;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #fff;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

button.float:hover {
  color: white;
}
button.float.active {
  color: white;
  background: #4ECDC4;
}

.buttonAnimate {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
  outline: none;
  background: white;
  border: none;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0 0.2rem 0.5rem #aaaaaa;
  overflow: hidden;
}
button.float:hover::before, button.float.active::before {
  visibility: visible;
}
button.float:hover svg,button.float.active svg {
  transition: 0.33s ease;
  transform: translate(-50%, -75%) scale(1.1);
}

.buttonAnimate svg {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
button.float::before, button.float.active::before  {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: scale(1.42) rotate(-45deg) transformX(-100%);
  visibility: hidden;
}
button.groups::before {
  background: #4ECDC4;
}
button.float:hover::after, button.float.active::after {
  visibility: visible;
  transform: translateY(45%);
  transition: 0.33s;
}
button.float::after {
  position: absolute;
  color: white;
  content: attr(data-text);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  visibility: hidden;
}

.btn-highlight {
  z-index: 1;
  overflow: hidden;
}
.btn-highlight::before {
  content: "";
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #50597b;
  z-index: -1;
  opacity: 1;

  transition: transform 550ms cubic-bezier(0.83, 0.89, 0, 0.7);
  transform: matrix(0.0001, 0, 0, 1, 0, 35);
}

.btn-highlight:hover::before, .btn-highlight.active::before {
  transform: matrix(1, 0, 0, 1, 0, 35);
} 


.btn-slideX {
  z-index: 1;
  overflow: hidden;
}
.btn-slideX::before {
  content: "";
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;

  transition: transform 350ms ease-in-out;
  transform: scalex(0);
  transform-origin: left;
}

.btn-slideX:hover::before, .btn-slideX.active::before {
  transform: scalex(1);
} 

.tooltip{
  position: relative;
}
.tooltip-text{
  position: absolute;
  
  border-radius: 6px;
  background-color: #94A3BB;
  padding: 4px 10px;
  width: auto;
  white-space: nowrap;
  display:none;
  z-index: 9999 ;
  font-family: Lato;
}
.tooltip-text span {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color:#94A3BB ;
 
}

.tooltip-right-top{
  bottom:25px;
  left:-8px;
}
.tooltip-right-top span{
  bottom: -4px;
}
.tooltip-left{
  bottom: -4px ;
  right: 32px;
}
.tooltip-left span{
  right: -4px;
  top: 8px
}
.tooltip-left-top{
  bottom: 25px ;
  right: -8px;
}
.tooltip-left-top span{
  bottom: -4px;
  right: 10px
}

.tooltip:hover > .tooltip-text{
  display:block;
}
.rotate-\[90deg\].tooltip > .tooltip-text {
  display:none;
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v14/xn7gYHE41ni1AdIRggexSg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v14/xn7gYHE41ni1AdIRggOxSuXd.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
input:-internal-autofill-selected {
  background-color: white !important;
}
.image-upload-block {
  margin-bottom: 10px;
}
.image-upload input {
  display: none;
}
.image-upload-block img {
  padding-right: 10px;
}
.MuiSelect-multiple{
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  border-radius: 25px !important;
}
.blockquote-footer::before {
  content:"\2014\00A0"
 }
svg.MuiSelect-icon.MuiSelect-iconOutlined{
width:30px;
height:30px;
top:10px;
right: -5px;
}
.MuiInputLabel-formControl{

  color : #383635 !important;
  font-family: Sora !important;
  font-weight: 500 !important;
}
.MuiInputLabel-formControl.Mui-focused{
  font-size: 1.2rem!important;
}
.radio-custom-input input{
  cursor: pointer
}
.radio-custom-input input:disabled{
  cursor: not-allowed
}
.font-lexend-deca{
  font-family: 'Lexend Deca'
}
.ql-editor{
  padding: 0px !important;
}
.ql-container.ql-snow{
  padding : 12px 15px !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;